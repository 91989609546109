<template>
  <div class="site-analytics-camera">
    <v-sheet
      v-resize-observer="onContentResized"
      class="tabs z-index-0"
      :dark="isTimelinePage"
    >
      <v-tabs height="35">
        <v-tab
          :to="`timeline?selected_timestamp=${new Date().toISOString()}&selected_mode=detections`"
          nuxt
        >
          Timeline
        </v-tab>
        <v-tab :to="`smart-search`" nuxt> Advanced Search </v-tab>
        <v-tab :to="`reports`" nuxt> Reports </v-tab>
      </v-tabs>
    </v-sheet>

    <NuxtChild
      ref="content"
      v-resize-observer="onContentResized"
      :style="contentStyle"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import { PERMISSIONS } from "@/constants/permissions"
import { useSiteAnalyticsStore } from "@/stores/siteAnalytics"
import { mapStores } from "pinia"
import { useBreadcrumbStore } from "~/stores/breadcrumb"

export default Vue.extend({
  meta: {
    requiredPermissions: [PERMISSIONS.SITE_ANALYTICS.VIEW],
  },
  name: "SiteAnalyticsCamera",
  async asyncData({ params }) {
    const cameraExid = params.camera_exid
    const siteAnalyticsStore = useSiteAnalyticsStore()

    if (!cameraExid) {
      return
    } else if (cameraExid !== siteAnalyticsStore.selectedCamera?.exid) {
      await siteAnalyticsStore.selectCamera(cameraExid)
    }

    const breadcrumbStore = useBreadcrumbStore()
    breadcrumbStore.breadcrumbs = [
      {
        name: "Home",
        href: "/",
        icon: "fa-house",
      },
      {
        name: "Site Analytics",
        href: "/site-analytics",
        icon: "fa-chart-simple",
      },
      {
        name: `${siteAnalyticsStore.selectedCamera.name} (${siteAnalyticsStore.selectedCamera.exid})`,
        icon: "fa-camera",
      },
    ]
  },
  data() {
    return {
      contentStyle: {},
    }
  },
  head() {
    return {
      title: `Site Analytics | ${
        useSiteAnalyticsStore().selectedCamera?.name || "Evercam"
      }`,
    }
  },
  computed: {
    ...mapStores(useSiteAnalyticsStore),
    isTimelinePage() {
      return this.$route.name.includes("timeline")
    },
  },

  methods: {
    onContentResized() {
      this.$setTimeout(() => {
        const content = this.$refs?.content?.$el?.getBoundingClientRect()
        this.contentStyle = {
          height: `${window.innerHeight - content?.top || 0}px !important`,
        }
      }, 500)
    },
  },
})
</script>

<style scoped lang="scss">
.tabs {
  position: relative;
  z-index: 99;
  box-shadow: 0 14px 10px -14px #0000002e;
}
</style>
